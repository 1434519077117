body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.common-font {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root,.App {
  height: 100%;
}

.react-datepicker-wrapper {
  width: 100%;
}

.rc-slider-disabled {
  background-color: transparent;
}

.patient__table.worklist--heightleft {
  height: calc(100vh - 57px);
}

.test__monitoring--btns {
  padding-top: 15px;
  text-align: right;
}
.test__monitoring--btns .test__monitoring--lists {
  display: inline-flex;
  flex-wrap: wrap;
  margin-left: -10px;
}
.test__monitoring--btns .test__monitoring--lists li {
  padding: 0 10px;
}
.test__monitoring--btns .test__monitoring--lists li .btn {
  min-width: 115px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.patient__view--list .patient__view--top {
  position: relative;
}
.patient__view--list .patient__view--timer {
  padding-right: 25px;
}
.device__delete--btn {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.device__delete--btn .btn {
  padding: 5px 4px;
  font-size: 0.875rem;
  line-height: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.app_version{
  font-size: 0.694444rem;
  background-color: rgba(23,25,35,0.7);
  position: fixed;
  bottom: 0.444444rem;
  right: 0.444444rem;
  padding: 0.296296rem;
  z-index: 1000;
  border-radius: 0.296296rem;
  margin: 0;
}

.disabledTab{
  pointer-events: none;
}

@media print {
  .col-print-1 {
    flex: 0 0 8.333333333333332%;
    max-width: 8.333333333333332%;
  }
  .col-print-2 {
    flex: 0 0 16.666666666666664%;
    max-width: 16.666666666666664%;
  }
  .col-print-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-print-4 {
    flex: 0 0 33.33333333333333%;
    max-width: 33.33333333333333%;
  }
  .col-print-5 {
    flex: 0 0 41.66666666666667%;
    max-width: 41.66666666666667%;
  }
  .col-print-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-print-7 {
    flex: 0 0 58.333333333333336%;
    max-width: 58.333333333333336%;
  }
  .col-print-8 {
    flex: 0 0 66.66666666666666%;
    max-width: 66.66666666666666%;
  }
  .col-print-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-print-10 {
    flex: 0 0 83.33333333333334%;
    max-width: 83.33333333333334%;
  }
  .col-print-11 {
    flex: 0 0 91.66666666666666%;
    max-width: 91.66666666666666%;
  }
  .col-print-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}



@media print {

  html, body {
    height:100vh; 
    margin: 0 !important; 
    padding: 0 !important;
    overflow: hidden;
  }

}